import { default as NextLink } from 'next/link';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FooterLinks, Maybe } from '../../types/contentstack';
import { mediaScreen } from '../../utils/mediaScreen';
import { Col, ResponsiveContainer, Row } from '../responsive';
import { Angle } from '../ui/angle';
import { Button } from '../ui/button';

export const Footer: FunctionComponent<IFooter> = ({
  title,
  text,
  button,
  onButtonClick,
  links,
  withAngle,
}) => {
  return (
    <FullWidthDelimiter>
      {withAngle && <Angle position="top-right" />}

      <WidthDelimiter>
        <Container>
          <CTAContainer>
            <ResponsiveContainer>
              <Row>
                <Col xs={5} md={7} className="image-container">
                  <img
                    width="431"
                    height="704"
                    className="image"
                    src="/img/building2.svg"
                    alt="footer buildings"
                  />
                </Col>

                <Col xs={7} md={5} className="cta-texts">
                  <CTATitle>{title}</CTATitle>
                  <CTAText>{text}</CTAText>
                  <Button onClick={onButtonClick}>{button}</Button>
                </Col>
              </Row>
            </ResponsiveContainer>

            <img
              width="1336"
              height="2"
              src="/img/footer-line.svg"
              alt="footer line"
              className="line"
            />
          </CTAContainer>

          <Content>
            <Row>
              <Col md={5} className="logo-container">
                <img
                  width="199"
                  height="62"
                  src="/img/footer-logo.svg"
                  alt="mapped logo"
                />

                <Copyright className="desktop-only">
                  © Mapped {new Date().getFullYear()}. All rights reserved.
                  <br />
                  SOC compliant.
                </Copyright>
              </Col>

              <Col md={4}>
                {links?.map((link) => {
                  return (
                    <NextLink
                      className="link"
                      key={link?.href}
                      href={link?.href!}
                      target={
                        link?.href?.startsWith('http') ? '_blank' : '_self'
                      }
                    >
                      {link?.title}
                    </NextLink>
                  );
                })}
              </Col>

              <Col md={3}>
                <Social>
                  <a
                    href="https://www.linkedin.com/company/mapped/"
                    target="_blank"
                  >
                    <img
                      src="/img/footer-linkedin.svg"
                      width="32"
                      height="32"
                      alt="linkedin icon"
                    />
                  </a>

                  <a href="https://twitter.com/Mapped" target="_blank">
                    <img
                      src="/img/footer-twitter.svg"
                      width="32"
                      height="32"
                      alt="twitter icon"
                    />
                  </a>

                  <a href="https://github.com/mapped" target="_blank">
                    <img
                      src="/img/footer-github.svg"
                      width="32"
                      height="32"
                      alt="github icon"
                    />
                  </a>
                </Social>

                <Compliance>
                  <a
                    href="https://www.prescientsecurity.com/iso-27001-certification-journey"
                    target="_blank"
                    title="ISO 27001 certified"
                  >
                    <img
                      src="/img/iso-logo.png"
                      width={120}
                      height={120}
                      alt="ISO 27001 certified"
                    />
                  </a>

                  <a
                    href="http://www.aicpa.org/soc4so"
                    target="_blank"
                    title="SOC compliant"
                  >
                    <img
                      src="/img/soc-logo.png"
                      width={120}
                      height={120}
                      alt="SOC compliant"
                    />
                  </a>
                </Compliance>

                <Copyright className="mobile-only">
                  © Mapped {new Date().getFullYear()}. All rights reserved.
                  <br />
                  SOC compliant.
                </Copyright>
              </Col>
            </Row>
          </Content>
        </Container>
      </WidthDelimiter>
    </FullWidthDelimiter>
  );
};

export const FOOTER_QUERY = `
  footer(uid: "bltd338e8bb3605855d") {
    links {
      title
      href
    }
  }
`;

const FullWidthDelimiter = styled.div`
  position: relative;
  padding-top: 100px;
  background-image: linear-gradient(180deg, white, #efedfb);
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;

  .desktop-only {
    display: block;
  }

  .mobile-only {
    display: none;
  }

  ${mediaScreen('md', 'lg')} {
    padding-top: 250px;
  }

  ${mediaScreen('xs', 'md')} {
    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: block;
    }

    padding-top: 70px;
  }
`;

const WidthDelimiter = styled.footer`
  width: 100%;
  margin: 0 auto;
  max-width: 1600px;
  position: relative;
`;

const Container = styled.div`
  width: 100%;

  .line {
    position: relative;
    top: -1px;
    left: -10px;
    width: 100%;
    z-index: 10;
  }

  .link {
    color: ${({ theme }) => theme.colors.slate1};
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
    font-weight: bold;
    margin-bottom: 16px;
    cursor: pointer;
    display: block;

    ${mediaScreen('xs', 'md')} {
      margin: 10% 0;
      text-align: center;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CTAContainer = styled.div`
  z-index: 11;

  .image-container {
    display: flex;
    align-items: flex-end;
  }

  .image {
    width: 100%;
    position: relative;
    bottom: 0;
    left: -30%;
    z-index: 5;
  }

  .cta-texts {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    button {
      width: auto;
    }
  }

  ${mediaScreen('xs', 'md')} {
    .image {
      margin-top: 0;
      left: -90%;
      width: 165%;
      max-width: none;
    }

    .cta-texts {
      padding: 40px 0;
    }
  }
`;

const CTATitle = styled.h2`
  font-size: 50px;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors.slate1};
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 24px;

  ${mediaScreen('xs', 'md')} {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

const CTAText = styled.p`
  color: ${({ theme }) => theme.colors.slate1};
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 48px;

  ${mediaScreen('xs', 'md')} {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 32px;
  }
`;

const Copyright = styled.p`
  margin: 0;
  margin-top: 30px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.2px;
  color: ${(props) => props.theme.colors.slate1};

  ${mediaScreen('xs', 'md')} {
    margin-top: 0px;
    text-align: center;
  }
`;

const Content = styled(ResponsiveContainer)`
  padding: 5% 0;

  ${mediaScreen('xs', 'md')} {
    > div {
      flex-direction: column;
      align-items: center;

      > div {
        width: auto;
        margin: 2% 0;
      }
    }

    .logo-container {
      text-align: center;
      margin-top: 30px;

      img {
        margin: 0 auto;
      }
    }

    ${Copyright} {
      margin-bottom: 20px;
    }
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: flex-end;

  ${mediaScreen('xs', 'md')} {
    justify-content: center;
  }

  a {
    display: block;
    text-decoration: none !important;
    margin-left: 32px;

    ${mediaScreen('xs', 'md')} {
      margin: 24px;
    }
  }

  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
    pointer-events: all;

    &:hover {
      opacity: 0.6;
    }
  }
`;

const Compliance = styled.div`
  display: flex;
  justify-content: center;

  ${mediaScreen('md')} {
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 0;

    a:last-of-type {
      margin-left: 20px;
      margin-right: -10px;
    }
  }

  ${mediaScreen('xs', 'md')} {
    padding: 20px 0;
    flex-direction: column;
    align-items: center;

    a:last-of-type {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
`;

interface IFooter {
  title?: string | null;
  text?: string | null;
  button?: string | null;
  onButtonClick?: () => void;
  links?: Maybe<Maybe<FooterLinks>[]>;
  withAngle?: boolean;
}
