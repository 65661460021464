import { FunctionComponent } from 'react';
import { CSSProperties } from 'styled-components';

export const Angle: FunctionComponent<IAngle> = ({ position, style }) => {
  return (
    <img
      width="422"
      height="249"
      className={`angle ${position}`}
      src={`/img/angle-${position}.svg`}
      style={{ zIndex: 3, ...style }}
    />
  );
};

interface IAngle {
  position: 'top-right' | 'bottom-left';
  style?: CSSProperties;
}
