import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import GraphqlCodeBlock from '../../lib/graphql-code-block';
import { mediaScreen } from '../../utils/mediaScreen';
import { Row } from '../responsive';

export const CodeSnippets: FunctionComponent = () => {
  const [tab, setTab] = useState(1);

  return (
    <Container className="code-snippets">
      <TabsWrapper>
        <Tabs>
          <Tab isActive={tab === 1} onClick={() => setTab(1)}>
            FLOOR GEOMETRY
          </Tab>

          <Tab isActive={tab === 2} onClick={() => setTab(2)}>
            GATHER SENSORS
          </Tab>

          <Tab isActive={tab === 3} onClick={() => setTab(3)}>
            TEMPERATURE READINGS
          </Tab>
        </Tabs>
      </TabsWrapper>

      <Row style={{ flexWrap: 'nowrap' }}>
        <div style={{ flex: 1, overflowX: 'auto' }}>
          <CodeBlock queryBody={getQueryBody(tab)}></CodeBlock>
        </div>

        <img
          src={`/img/code-preview-${tab}.svg`}
          width="350"
          height="350"
          className="preview image"
          alt={`code preview ${tab}`}
        />
      </Row>
    </Container>
  );
};

function getQueryBody(tab: number) {
  switch (tab) {
    case 1:
      return `
        {
          buildings(filter: { id: { eq: "123456abcdef" } }) {
            floors {
              id
              name
              geoshape
            }
          }
        }
      `;

    case 2:
      return `
        {
          floors(filter: { id: { eq: "123456abcdef" } }) {
            things(filter: { type: { eq: "Sensor" } }) {
              id
              name
              model {
                name
                manufacturer {
                  name
                }
              }
            }
          }
        }
      `;

    case 3:
      return `
        {
          things(filter: { type: { eq: "Thermostat" } }) {
            points(filter: { type: { eq: "TemperatureSetPoint" } }) {
              name
              aggregation(period: HOUR) {
                avg
                timestamp
              }
            }
          }
        }
      `;
  }

  return '';
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 35px 55px;
  position: relative;
  background-image: linear-gradient(
      to left,
      rgba(38, 47, 64, 0),
      #262f40,
      rgba(38, 47, 64, 0)
    ),
    linear-gradient(to right, #181e2b, #181e2b);

  ${mediaScreen('xs', 'md')} {
    width: 100%;
    padding: 35px 0;
    padding-bottom: 0;
  }

  .preview {
    width: 350px;
    height: 350px;
    position: relative;
    background: white;
    border-radius: 20px;

    ${mediaScreen('xs', 'md')} {
      display: none;
    }
  }
`;

const TabsWrapper = styled.div`
  overflow: hidden;
  height: 40px;
  margin-bottom: 50px;

  ${mediaScreen('xs', 'sm')} {
    height: 30px;
  }
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;

  ${mediaScreen('xs', 'sm')} {
    justify-content: flex-start;
    overflow: auto;
  }
`;

const Tab = styled.div<{ isActive?: boolean }>`
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.5;
  padding-bottom: 8px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin: 0 16px;

  ${mediaScreen('xs', 'sm')} {
    font-size: 12px;
    white-space: nowrap;
  }

  &:hover {
    opacity: 0.76;
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    opacity: 1 !important;
    border-color: ${theme.colors.teal};
  `}
`;

const CodeBlock = styled(GraphqlCodeBlock)`
  color: #a2aaad;
  font-size: 18px;

  pre {
    margin: 2px 0;
  }

  .attribute {
    color: white;
  }

  .property {
    color: rgb(129, 147, 177);
  }

  .string {
    color: rgb(86, 191, 172);
  }

  ${mediaScreen('xs', 'md')} {
    font-size: 14px;
    padding-bottom: 40px;
  }

  ${mediaScreen('xs', 'sm')} {
    overflow-x: auto;
  }
`;
